// eslint-disable-next-line import/named
import { ActionTree, MutationTree } from 'vuex';
import { Section } from '~/@types/exam';
import { ApiDictionary, FetchingParams, StandardResponse } from '~/@types/meta';

interface searchQuery {
  searchQuery: string;
  status_type: string[];
  format_ids: number[];
  type_ids: number[];
  difficulties: number[];
  section_ids: number[];
  author_ids: number[];
  exam_ids: number[];
  question_id: number | null;
  template_id: number | null;
  exam_id: number | null;
}

interface StateInterface {
  questions: any[];
  modules: any[];
  examSections: Section[];
  examTemplates: any[];
  difficulties: number[];
  questionFormats: ApiDictionary[];
  questionTypes: ApiDictionary[];
  query: searchQuery;
  isFetching: boolean;
  fetchingParams: FetchingParams;
}

/**
 * States
 */
export const state = (): StateInterface => ({
  questions: [],
  modules: [],
  examSections: [],
  examTemplates: [],
  difficulties: [],
  questionFormats: [],
  questionTypes: [],
  isFetching: false,
  fetchingParams: {
    perPage: 15,
    page: 1,
    orderBy: [],
    orderDesc: [],
  },
  query: {
    searchQuery: '',
    status_type: [],
    type_ids: [],
    format_ids: [],
    section_ids: [],
    author_ids: [],
    difficulties: [],
    question_id: null,
    template_id: null,
    exam_id: null,
    exam_ids: [],
  },
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  /**
   * Get system updates
   *
   * @param commit
   * @param state
   * @param loginData
   * @constructor
   */
  GET_MODULES({ commit, state }, questions: any): any {
    return new Promise((resolve) => {
      const queryParams = {
        ...state.fetchingParams,
        ...state.query,
      };

      // @ts-ignore
      (this as any).$api
        .get('/questions/paginate', queryParams)
        .then((response) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              response.data ||
              'Oops! something went wrong while getting the user list.',
              'error',
            );
            resolve(null);
          } else {
            commit('setCurrentListOfModules', response.data);
            resolve(response.data);
          }
        });
    });
  },
  GET_SECTIONS({ commit, state }, questions: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .get('/sections')
        .then((response: StandardResponse<Section>) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              'Something went wrong while getting exam Sections.',
              'error',
            );
            resolve(null);
          } else {
            commit('storeSections', response.data);
          }
        });
    });
  },
  GET_TEMPLATES({ commit, state }, published: boolean = false): any {
    return new Promise((resolve) => {
      const $url = '/templates?all=true';
      // @ts-ignore
      (this as any).$api.get($url).then((response: StandardResponse<any>) => {
        if (response.code !== 200) {
          // @ts-ignore
          (this as any).$alert.show(
            'Something went wrong while getting Exam Templates.',
            'error',
          );
          resolve(null);
        } else {
          commit('storeTemplates', response.data);
          resolve(response.data);
        }
      });
    });
  },
  FETCH_FORMATS({ commit, state }): any {
    if (state.questionFormats.length > 0) {
      return;
    }
    (this as any).$apiFactories.system
      .dictionary('module_formats')
      .then((response: StandardResponse<ApiDictionary>) => {
        commit('storeFormats', response.data);
      });
  },
  FETCH_TYPES({ commit, state }): any {
    if (state.questionTypes.length > 0) {
      return;
    }
    (this as any).$apiFactories.system
      .dictionary('module_types')
      .then((response: StandardResponse<ApiDictionary>) => {
        commit('storeTypes', response.data);
      });
  },
  FETCH_DIFFICULTIES({ commit, state }): any {
    if (state.difficulties.length > 0) {
      return;
    }
    (this as any).$apiFactories.exams
      .QuestionDifficulties()
      .then((response: number[]) => {
        commit('storeDifficulties', response);
      });
  },
};

/**
 * Mutations
 */
export const mutations: MutationTree<StateInterface> = {
  storeSections(state: any, sections: any[] | null) {
    state.examSections = sections;
  },
  storeTemplates(state: any, templates: any[] | null) {
    state.examTemplates = templates;
  },
  storeFormats(state: any, data: ApiDictionary[] | null) {
    state.questionFormats = data;
  },
  storeTypes(state: any, data: ApiDictionary[] | null) {
    state.questionTypes = data;
  },
  storeDifficulties(state: any, data: number[] | null) {
    state.difficulties = data;
  },
  setCurrentListOfModules(state: StateInterface, data) {
    state.modules = data.data;
  },
  setSearchData(state: StateInterface, searchData) {
    state.query.searchQuery = searchData.search;
    state.query.format_ids = searchData.format_ids;
    state.query.type_ids = searchData.type_ids;
    state.query.section_ids = searchData.section_ids;
    state.query.author_ids = searchData.author_ids;
    state.query.exam_ids = searchData.exam_ids;
    state.query.difficulties = searchData.difficulties;
    state.query.question_id = searchData.question_id;
    state.query.status_type = searchData.status_type;
    state.query.template_id = searchData.template_id;
    state.query.exam_id = searchData.exam_id;
  },
  setIsFetching(state, isUsersFetching: boolean) {
    state.isFetching = isUsersFetching;
  },
  setFetchParams(state: StateInterface, fetchingParams: FetchingParams) {
    state.fetchingParams = fetchingParams;
  },
};

/**
 * Getters
 */
export const getters = {
  dropdownFormats: (state: StateInterface) => {
    return state.questionFormats.map((el) => {
      return {
        text: el.value,
        value: el.id,
      };
    });
  },
  dropdownTypes: (state: StateInterface) => {
    return state.questionTypes.map((el) => {
      return {
        text: el.value,
        value: el.id,
      };
    });
  },
  dropdownDifficulties(state: StateInterface) {
    return state.difficulties.map((el) => {
      return {
        text: el.toString(),
        value: el,
      };
    });
  },
  dropdownSections(state: StateInterface) {
    return state.examSections.map((el: Section) => {
      return {
        text: el.name,
        value: el.id,
      };
    });
  },
  dropdownTemplates(state: StateInterface) {
    return state.examTemplates.map((el: Section) => {
      return {
        text: el.name,
        value: el.id,
      };
    });
  },
  dropdownPublishedTemplates(state: StateInterface) {
    return state.examTemplates
      .filter((el) => {
        return !!el.published_at;
      })
      .map((el: Section) => {
        return {
          text: el.name,
          value: el.id,
        };
      });
  },
};
