declare global {
  interface Window {
    hj: any;
    _hjSettings: any;
  }
}

export default ({ app }) => {
  if (process.client) {
    const hotjarID = '3909492'; // Replaced 'YOUR_HOTJAR_ID' with '3909492'
    const hotjarVersion = 6; // Replace '6' with the version number provided in your tracking code.

    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
      h._hjSettings = { hjid: hotjarID, hjsv: hotjarVersion };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
};