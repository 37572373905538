import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ec6ba3e = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _70493d71 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _41c8e242 = () => interopDefault(import('../pages/my.vue' /* webpackChunkName: "pages/my" */))
const _7981a176 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _591c5ee3 = () => interopDefault(import('../pages/my/account/index.vue' /* webpackChunkName: "pages/my/account/index" */))
const _cc5e21b4 = () => interopDefault(import('../pages/my/account/index/index.vue' /* webpackChunkName: "pages/my/account/index/index" */))
const _2c66486c = () => interopDefault(import('../pages/my/account/index/change-password.vue' /* webpackChunkName: "pages/my/account/index/change-password" */))
const _055849a2 = () => interopDefault(import('../pages/my/account/index/contacts.vue' /* webpackChunkName: "pages/my/account/index/contacts" */))
const _2501e43c = () => interopDefault(import('../pages/my/account/index/notifications.vue' /* webpackChunkName: "pages/my/account/index/notifications" */))
const _f1449800 = () => interopDefault(import('../pages/my/account/index/studentBase.vue' /* webpackChunkName: "pages/my/account/index/studentBase" */))
const _16ecdcf9 = () => interopDefault(import('../pages/my/account/index/subscription.vue' /* webpackChunkName: "pages/my/account/index/subscription" */))
const _254e54ae = () => interopDefault(import('../pages/my/base.vue' /* webpackChunkName: "pages/my/base" */))
const _7fcd74ae = () => interopDefault(import('../pages/my/results.vue' /* webpackChunkName: "pages/my/results" */))
const _38f5da00 = () => interopDefault(import('../pages/my/student-base.vue' /* webpackChunkName: "pages/my/student-base" */))
const _1a530a18 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _31cffa77 = () => interopDefault(import('../pages/onboarding/index/index.vue' /* webpackChunkName: "pages/onboarding/index/index" */))
const _13cc7b84 = () => interopDefault(import('../pages/onboarding/index/contacts.vue' /* webpackChunkName: "pages/onboarding/index/contacts" */))
const _8d5aea5c = () => interopDefault(import('../pages/onboarding/index/diagnostic.vue' /* webpackChunkName: "pages/onboarding/index/diagnostic" */))
const _eb65d9b4 = () => interopDefault(import('../pages/onboarding/index/enter-results.vue' /* webpackChunkName: "pages/onboarding/index/enter-results" */))
const _5e416e3c = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _686030fc = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _a811f5ea = () => interopDefault(import('../pages/start-a-practice/index.vue' /* webpackChunkName: "pages/start-a-practice/index" */))
const _6f90574e = () => interopDefault(import('../pages/start-a-practice/index/index.vue' /* webpackChunkName: "pages/start-a-practice/index/index" */))
const _8af2f44c = () => interopDefault(import('../pages/start-a-practice/index/_section.vue' /* webpackChunkName: "pages/start-a-practice/index/_section" */))
const _5df21fe1 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _1caa29ba = () => interopDefault(import('../pages/app/exams/index.vue' /* webpackChunkName: "pages/app/exams/index" */))
const _91726aca = () => interopDefault(import('../pages/app/ordering/index.vue' /* webpackChunkName: "pages/app/ordering/index" */))
const _b4f0ea08 = () => interopDefault(import('../pages/app/questions/index.vue' /* webpackChunkName: "pages/app/questions/index" */))
const _64e4855f = () => interopDefault(import('../pages/app/students/index.vue' /* webpackChunkName: "pages/app/students/index" */))
const _2b8895e8 = () => interopDefault(import('../pages/app/templates/index.vue' /* webpackChunkName: "pages/app/templates/index" */))
const _0690f3f7 = () => interopDefault(import('../pages/app/users/index.vue' /* webpackChunkName: "pages/app/users/index" */))
const _fb985eec = () => interopDefault(import('../pages/auth/refresh.vue' /* webpackChunkName: "pages/auth/refresh" */))
const _39e523e4 = () => interopDefault(import('../pages/auth/sign-in.vue' /* webpackChunkName: "pages/auth/sign-in" */))
const _4fd0611f = () => interopDefault(import('../pages/auth/sign-out.vue' /* webpackChunkName: "pages/auth/sign-out" */))
const _9b336036 = () => interopDefault(import('../pages/auth/signed-in.vue' /* webpackChunkName: "pages/auth/signed-in" */))
const _95cfb6d6 = () => interopDefault(import('../pages/oldauth/forget-password.vue' /* webpackChunkName: "pages/oldauth/forget-password" */))
const _1e46b103 = () => interopDefault(import('../pages/oldauth/login.vue' /* webpackChunkName: "pages/oldauth/login" */))
const _59c7ee2f = () => interopDefault(import('../pages/oldauth/reset-password.vue' /* webpackChunkName: "pages/oldauth/reset-password" */))
const _31d71eb9 = () => interopDefault(import('../pages/payment/cancel.vue' /* webpackChunkName: "pages/payment/cancel" */))
const _118dc519 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _4a88669c = () => interopDefault(import('../pages/payment/subscription.vue' /* webpackChunkName: "pages/payment/subscription" */))
const _1dc18354 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _a0bd2cf0 = () => interopDefault(import('../pages/payment/update.vue' /* webpackChunkName: "pages/payment/update" */))
const _e823ed9e = () => interopDefault(import('../pages/register/message.vue' /* webpackChunkName: "pages/register/message" */))
const _7cf2ac45 = () => interopDefault(import('../pages/app/questions/create/index.vue' /* webpackChunkName: "pages/app/questions/create/index" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _102f5a4b = () => interopDefault(import('../pages/app/exams/_id.vue' /* webpackChunkName: "pages/app/exams/_id" */))
const _7503bc8e = () => interopDefault(import('../pages/app/exams/_id/index.vue' /* webpackChunkName: "pages/app/exams/_id/index" */))
const _0a3a189a = () => interopDefault(import('../pages/app/exams/_id/_section.vue' /* webpackChunkName: "pages/app/exams/_id/_section" */))
const _80a6907a = () => interopDefault(import('../pages/app/ordering/_id.vue' /* webpackChunkName: "pages/app/ordering/_id" */))
const _42332006 = () => interopDefault(import('../pages/app/ordering/_id/index.vue' /* webpackChunkName: "pages/app/ordering/_id/index" */))
const _b03b3bbc = () => interopDefault(import('../pages/app/ordering/_id/_section.vue' /* webpackChunkName: "pages/app/ordering/_id/_section" */))
const _32341c64 = () => interopDefault(import('../pages/app/questions/_id.vue' /* webpackChunkName: "pages/app/questions/_id" */))
const _b12a7032 = () => interopDefault(import('../pages/app/questions/_id/index.vue' /* webpackChunkName: "pages/app/questions/_id/index" */))
const _4f873cce = () => interopDefault(import('../pages/app/questions/_id/content.vue' /* webpackChunkName: "pages/app/questions/_id/content" */))
const _5570945a = () => interopDefault(import('../pages/app/questions/_id/_question.vue' /* webpackChunkName: "pages/app/questions/_id/_question" */))
const _212dd8ca = () => interopDefault(import('../pages/app/students/_id/index.vue' /* webpackChunkName: "pages/app/students/_id/index" */))
const _4239c066 = () => interopDefault(import('../pages/app/students/_id/index/index.vue' /* webpackChunkName: "pages/app/students/_id/index/index" */))
const _8a8c2dba = () => interopDefault(import('../pages/app/students/_id/index/account-info.vue' /* webpackChunkName: "pages/app/students/_id/index/account-info" */))
const _16b399b4 = () => interopDefault(import('../pages/app/students/_id/index/base.vue' /* webpackChunkName: "pages/app/students/_id/index/base" */))
const _45c0d5b0 = () => interopDefault(import('../pages/app/students/_id/index/contacts.vue' /* webpackChunkName: "pages/app/students/_id/index/contacts" */))
const _328cb3d3 = () => interopDefault(import('../pages/app/students/_id/index/dashboard-view.vue' /* webpackChunkName: "pages/app/students/_id/index/dashboard-view" */))
const _faf9979e = () => interopDefault(import('../pages/app/students/_id/index/results.vue' /* webpackChunkName: "pages/app/students/_id/index/results" */))
const _b2b75d7c = () => interopDefault(import('../pages/app/students/_id/index/study-plan.vue' /* webpackChunkName: "pages/app/students/_id/index/study-plan" */))
const _6ae0fcf1 = () => interopDefault(import('../pages/app/students/_id/index/subscriptions.vue' /* webpackChunkName: "pages/app/students/_id/index/subscriptions" */))
const _56da1b60 = () => interopDefault(import('../pages/app/templates/_id.vue' /* webpackChunkName: "pages/app/templates/_id" */))
const _7f3769f7 = () => interopDefault(import('../pages/exam/_hash_id.vue' /* webpackChunkName: "pages/exam/_hash_id" */))
const _f397498c = () => interopDefault(import('../pages/exam/_hash_id/index.vue' /* webpackChunkName: "pages/exam/_hash_id/index" */))
const _732aea6e = () => interopDefault(import('../pages/exam/_hash_id/_section.vue' /* webpackChunkName: "pages/exam/_hash_id/_section" */))
const _0149dd5b = () => interopDefault(import('../pages/practice/_hash_id.vue' /* webpackChunkName: "pages/practice/_hash_id" */))
const _6d5898c4 = () => interopDefault(import('../pages/practice/_hash_id/index.vue' /* webpackChunkName: "pages/practice/_hash_id/index" */))
const _e04bf0ec = () => interopDefault(import('../pages/practice/_hash_id/_section.vue' /* webpackChunkName: "pages/practice/_hash_id/_section" */))
const _1b08ce33 = () => interopDefault(import('../pages/start-a-practice/_id.vue' /* webpackChunkName: "pages/start-a-practice/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app",
    component: _7ec6ba3e,
    name: "app"
  }, {
    path: "/dashboard",
    component: _70493d71,
    name: "dashboard"
  }, {
    path: "/my",
    component: _41c8e242,
    children: [{
      path: "",
      component: _7981a176,
      name: "my"
    }, {
      path: "account",
      component: _591c5ee3,
      children: [{
        path: "",
        component: _cc5e21b4,
        name: "my-account-index"
      }, {
        path: "change-password",
        component: _2c66486c,
        name: "my-account-index-change-password"
      }, {
        path: "contacts",
        component: _055849a2,
        name: "my-account-index-contacts"
      }, {
        path: "notifications",
        component: _2501e43c,
        name: "my-account-index-notifications"
      }, {
        path: "studentBase",
        component: _f1449800,
        name: "my-account-index-studentBase"
      }, {
        path: "subscription",
        component: _16ecdcf9,
        name: "my-account-index-subscription"
      }]
    }, {
      path: "base",
      component: _254e54ae,
      name: "my-base"
    }, {
      path: "results",
      component: _7fcd74ae,
      name: "my-results"
    }, {
      path: "student-base",
      component: _38f5da00,
      name: "my-student-base"
    }]
  }, {
    path: "/onboarding",
    component: _1a530a18,
    children: [{
      path: "",
      component: _31cffa77,
      name: "onboarding-index"
    }, {
      path: "contacts",
      component: _13cc7b84,
      name: "onboarding-index-contacts"
    }, {
      path: "diagnostic",
      component: _8d5aea5c,
      name: "onboarding-index-diagnostic"
    }, {
      path: "enter-results",
      component: _eb65d9b4,
      name: "onboarding-index-enter-results"
    }]
  }, {
    path: "/privacy",
    component: _5e416e3c,
    name: "privacy"
  }, {
    path: "/register",
    component: _686030fc,
    name: "register"
  }, {
    path: "/start-a-practice",
    component: _a811f5ea,
    children: [{
      path: "",
      component: _6f90574e,
      name: "start-a-practice-index"
    }, {
      path: ":section",
      component: _8af2f44c,
      name: "start-a-practice-index-section"
    }]
  }, {
    path: "/terms",
    component: _5df21fe1,
    name: "terms"
  }, {
    path: "/app/exams",
    component: _1caa29ba,
    name: "app-exams"
  }, {
    path: "/app/ordering",
    component: _91726aca,
    name: "app-ordering"
  }, {
    path: "/app/questions",
    component: _b4f0ea08,
    name: "app-questions"
  }, {
    path: "/app/students",
    component: _64e4855f,
    name: "app-students"
  }, {
    path: "/app/templates",
    component: _2b8895e8,
    name: "app-templates"
  }, {
    path: "/app/users",
    component: _0690f3f7,
    name: "app-users"
  }, {
    path: "/auth/refresh",
    component: _fb985eec,
    name: "auth-refresh"
  }, {
    path: "/auth/sign-in",
    component: _39e523e4,
    name: "auth-sign-in"
  }, {
    path: "/auth/sign-out",
    component: _4fd0611f,
    name: "auth-sign-out"
  }, {
    path: "/auth/signed-in",
    component: _9b336036,
    name: "auth-signed-in"
  }, {
    path: "/oldauth/forget-password",
    component: _95cfb6d6,
    name: "oldauth-forget-password"
  }, {
    path: "/oldauth/login",
    component: _1e46b103,
    name: "oldauth-login"
  }, {
    path: "/oldauth/reset-password",
    component: _59c7ee2f,
    name: "oldauth-reset-password"
  }, {
    path: "/payment/cancel",
    component: _31d71eb9,
    name: "payment-cancel"
  }, {
    path: "/payment/error",
    component: _118dc519,
    name: "payment-error"
  }, {
    path: "/payment/subscription",
    component: _4a88669c,
    name: "payment-subscription"
  }, {
    path: "/payment/success",
    component: _1dc18354,
    name: "payment-success"
  }, {
    path: "/payment/update",
    component: _a0bd2cf0,
    name: "payment-update"
  }, {
    path: "/register/message",
    component: _e823ed9e,
    name: "register-message"
  }, {
    path: "/app/questions/create",
    component: _7cf2ac45,
    name: "app-questions-create"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/app/exams/:id",
    component: _102f5a4b,
    children: [{
      path: "",
      component: _7503bc8e,
      name: "app-exams-id"
    }, {
      path: ":section",
      component: _0a3a189a,
      name: "app-exams-id-section"
    }]
  }, {
    path: "/app/ordering/:id",
    component: _80a6907a,
    children: [{
      path: "",
      component: _42332006,
      name: "app-ordering-id"
    }, {
      path: ":section",
      component: _b03b3bbc,
      name: "app-ordering-id-section"
    }]
  }, {
    path: "/app/questions/:id",
    component: _32341c64,
    children: [{
      path: "",
      component: _b12a7032,
      name: "app-questions-id"
    }, {
      path: "content",
      component: _4f873cce,
      name: "app-questions-id-content"
    }, {
      path: ":question",
      component: _5570945a,
      name: "app-questions-id-question"
    }]
  }, {
    path: "/app/students/:id",
    component: _212dd8ca,
    children: [{
      path: "",
      component: _4239c066,
      name: "app-students-id-index"
    }, {
      path: "account-info",
      component: _8a8c2dba,
      name: "app-students-id-index-account-info"
    }, {
      path: "base",
      component: _16b399b4,
      name: "app-students-id-index-base"
    }, {
      path: "contacts",
      component: _45c0d5b0,
      name: "app-students-id-index-contacts"
    }, {
      path: "dashboard-view",
      component: _328cb3d3,
      name: "app-students-id-index-dashboard-view"
    }, {
      path: "results",
      component: _faf9979e,
      name: "app-students-id-index-results"
    }, {
      path: "study-plan",
      component: _b2b75d7c,
      name: "app-students-id-index-study-plan"
    }, {
      path: "subscriptions",
      component: _6ae0fcf1,
      name: "app-students-id-index-subscriptions"
    }]
  }, {
    path: "/app/templates/:id",
    component: _56da1b60,
    name: "app-templates-id"
  }, {
    path: "/exam/:hash_id?",
    component: _7f3769f7,
    children: [{
      path: "",
      component: _f397498c,
      name: "exam-hash_id"
    }, {
      path: ":section",
      component: _732aea6e,
      name: "exam-hash_id-section"
    }]
  }, {
    path: "/practice/:hash_id?",
    component: _0149dd5b,
    children: [{
      path: "",
      component: _6d5898c4,
      name: "practice-hash_id"
    }, {
      path: ":section",
      component: _e04bf0ec,
      name: "practice-hash_id-section"
    }]
  }, {
    path: "/start-a-practice/:id?",
    component: _1b08ce33,
    name: "start-a-practice-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
