export const BarChart = () => import('../../components/BarChart.vue' /* webpackChunkName: "components/bar-chart" */).then(c => wrapFunctional(c.default || c))
export const HorizontalBarChart = () => import('../../components/HorizontalBarChart.vue' /* webpackChunkName: "components/horizontal-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/date-picker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const ViewTime = () => import('../../components/view-time.vue' /* webpackChunkName: "components/view-time" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/alerts/index.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const DashboardNextBestActionBlock = () => import('../../components/dashboard/next-best-action-block.vue' /* webpackChunkName: "components/dashboard-next-best-action-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardNextBestActionSalesBlock = () => import('../../components/dashboard/next-best-action-sales-block.vue' /* webpackChunkName: "components/dashboard-next-best-action-sales-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardReportBlock = () => import('../../components/dashboard/report-block.vue' /* webpackChunkName: "components/dashboard-report-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardStudyPlanBlock = () => import('../../components/dashboard/study-plan-block.vue' /* webpackChunkName: "components/dashboard-study-plan-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardViewStudentBlock = () => import('../../components/dashboard/view-student-block.vue' /* webpackChunkName: "components/dashboard-view-student-block" */).then(c => wrapFunctional(c.default || c))
export const DialogsConfirm = () => import('../../components/dialogs/confirm.vue' /* webpackChunkName: "components/dialogs-confirm" */).then(c => wrapFunctional(c.default || c))
export const DialogsInfo = () => import('../../components/dialogs/info.vue' /* webpackChunkName: "components/dialogs-info" */).then(c => wrapFunctional(c.default || c))
export const ExamDiagnosticSelectorDialog = () => import('../../components/exam/diagnostic-selector-dialog.vue' /* webpackChunkName: "components/exam-diagnostic-selector-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExamEnterBenchmarks = () => import('../../components/exam/enter-benchmarks.vue' /* webpackChunkName: "components/exam-enter-benchmarks" */).then(c => wrapFunctional(c.default || c))
export const ExamResults = () => import('../../components/exam/exam-results.vue' /* webpackChunkName: "components/exam-results" */).then(c => wrapFunctional(c.default || c))
export const ExamSelectorDialog = () => import('../../components/exam/exam-selector-dialog.vue' /* webpackChunkName: "components/exam-selector-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExamSectionReview = () => import('../../components/exam/section-review.vue' /* webpackChunkName: "components/exam-section-review" */).then(c => wrapFunctional(c.default || c))
export const ExamSectionTestDialog = () => import('../../components/exam/section-test-dialog.vue' /* webpackChunkName: "components/exam-section-test-dialog" */).then(c => wrapFunctional(c.default || c))
export const FieldsAdvisorsInput = () => import('../../components/fields/AdvisorsInput.vue' /* webpackChunkName: "components/fields-advisors-input" */).then(c => wrapFunctional(c.default || c))
export const FieldsTimeFieldInput = () => import('../../components/fields/time-field-input.vue' /* webpackChunkName: "components/fields-time-field-input" */).then(c => wrapFunctional(c.default || c))
export const QuestionsMultiChoiceCheckbox = () => import('../../components/questions/multi-choice-checkbox.vue' /* webpackChunkName: "components/questions-multi-choice-checkbox" */).then(c => wrapFunctional(c.default || c))
export const QuestionsQuestionMultiBase = () => import('../../components/questions/question-multi-base.vue' /* webpackChunkName: "components/questions-question-multi-base" */).then(c => wrapFunctional(c.default || c))
export const QuestionsQuestionViewBase = () => import('../../components/questions/question-view-base.vue' /* webpackChunkName: "components/questions-question-view-base" */).then(c => wrapFunctional(c.default || c))
export const QuestionsQuestionViewLayout = () => import('../../components/questions/question-view-layout.vue' /* webpackChunkName: "components/questions-question-view-layout" */).then(c => wrapFunctional(c.default || c))
export const ResultsPastResultsView = () => import('../../components/results/pastResultsView.vue' /* webpackChunkName: "components/results-past-results-view" */).then(c => wrapFunctional(c.default || c))
export const StudentsAssigneesTutors = () => import('../../components/students/assignees-tutors.vue' /* webpackChunkName: "components/students-assignees-tutors" */).then(c => wrapFunctional(c.default || c))
export const StudentsExamDate = () => import('../../components/students/exam-date.vue' /* webpackChunkName: "components/students-exam-date" */).then(c => wrapFunctional(c.default || c))
export const StudentsLinkedContactView = () => import('../../components/students/linked-contact-view.vue' /* webpackChunkName: "components/students-linked-contact-view" */).then(c => wrapFunctional(c.default || c))
export const StudentsSubscriptionCurrent = () => import('../../components/students/subscription-current.vue' /* webpackChunkName: "components/students-subscription-current" */).then(c => wrapFunctional(c.default || c))
export const StudentsSubscriptionEditor = () => import('../../components/students/subscription-editor.vue' /* webpackChunkName: "components/students-subscription-editor" */).then(c => wrapFunctional(c.default || c))
export const StudentsSubscriptionHistory = () => import('../../components/students/subscription-history.vue' /* webpackChunkName: "components/students-subscription-history" */).then(c => wrapFunctional(c.default || c))
export const BlocksBlockTitleRow = () => import('../../components/blocks/block-title-row/index.vue' /* webpackChunkName: "components/blocks-block-title-row" */).then(c => wrapFunctional(c.default || c))
export const BlocksQuestionPageLayout = () => import('../../components/blocks/question-page-layout/index.vue' /* webpackChunkName: "components/blocks-question-page-layout" */).then(c => wrapFunctional(c.default || c))
export const BlocksSecondaryNavigation = () => import('../../components/blocks/secondary-navigation/index.vue' /* webpackChunkName: "components/blocks-secondary-navigation" */).then(c => wrapFunctional(c.default || c))
export const BlocksStepper = () => import('../../components/blocks/stepper/index.vue' /* webpackChunkName: "components/blocks-stepper" */).then(c => wrapFunctional(c.default || c))
export const AccountEditAccount = () => import('../../components/account/edit-account/index.vue' /* webpackChunkName: "components/account-edit-account" */).then(c => wrapFunctional(c.default || c))
export const AccountStudentSidebar = () => import('../../components/account/student-sidebar/index.vue' /* webpackChunkName: "components/account-student-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ExamCreateExamDialog = () => import('../../components/exam/create-exam-dialog/index.vue' /* webpackChunkName: "components/exam-create-exam-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExamExamList = () => import('../../components/exam/exam-list/index.vue' /* webpackChunkName: "components/exam-exam-list" */).then(c => wrapFunctional(c.default || c))
export const ExamExamQuestionSelector = () => import('../../components/exam/exam-question-selector/index.vue' /* webpackChunkName: "components/exam-exam-question-selector" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsQuestionContent = () => import('../../components/exam/questions/question-content.vue' /* webpackChunkName: "components/exam-questions-question-content" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsQuestionList = () => import('../../components/exam/questions/question-list.vue' /* webpackChunkName: "components/exam-questions-question-list" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsQuestionSettings = () => import('../../components/exam/questions/question-settings.vue' /* webpackChunkName: "components/exam-questions-question-settings" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsQuestionSetupBase = () => import('../../components/exam/questions/question-setup-base.ts' /* webpackChunkName: "components/exam-questions-question-setup-base" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsQuestionTimer = () => import('../../components/exam/questions/question-timer.vue' /* webpackChunkName: "components/exam-questions-question-timer" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsSettings = () => import('../../components/exam/questions/settings.vue' /* webpackChunkName: "components/exam-questions-settings" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsTestQuestionPreview = () => import('../../components/exam/questions/test-question-preview.vue' /* webpackChunkName: "components/exam-questions-test-question-preview" */).then(c => wrapFunctional(c.default || c))
export const ExamTemplatesCreate = () => import('../../components/exam/templates/create.vue' /* webpackChunkName: "components/exam-templates-create" */).then(c => wrapFunctional(c.default || c))
export const ExamTemplatesList = () => import('../../components/exam/templates/list.vue' /* webpackChunkName: "components/exam-templates-list" */).then(c => wrapFunctional(c.default || c))
export const ExamTemplatesTemplateIntro = () => import('../../components/exam/templates/templateIntro.vue' /* webpackChunkName: "components/exam-templates-template-intro" */).then(c => wrapFunctional(c.default || c))
export const ExamTemplatesTemplateSection = () => import('../../components/exam/templates/templateSection.vue' /* webpackChunkName: "components/exam-templates-template-section" */).then(c => wrapFunctional(c.default || c))
export const ExamViewSectionInstructions = () => import('../../components/exam/view-section-instructions/index.vue' /* webpackChunkName: "components/exam-view-section-instructions" */).then(c => wrapFunctional(c.default || c))
export const FieldsFieldDate = () => import('../../components/fields/field-date/index.vue' /* webpackChunkName: "components/fields-field-date" */).then(c => wrapFunctional(c.default || c))
export const FieldsFieldImageUpload = () => import('../../components/fields/field-image-upload/index.vue' /* webpackChunkName: "components/fields-field-image-upload" */).then(c => wrapFunctional(c.default || c))
export const FieldsFieldLinkedContactEmail = () => import('../../components/fields/field-linked-contact-email/index.vue' /* webpackChunkName: "components/fields-field-linked-contact-email" */).then(c => wrapFunctional(c.default || c))
export const FieldsFieldRichtext = () => import('../../components/fields/field-richtext/index.vue' /* webpackChunkName: "components/fields-field-richtext" */).then(c => wrapFunctional(c.default || c))
export const FieldsetsFieldsetsEducationDetails = () => import('../../components/fieldsets/fieldsets-education-details/index.vue' /* webpackChunkName: "components/fieldsets-fieldsets-education-details" */).then(c => wrapFunctional(c.default || c))
export const FieldsetsFieldsetsPassword = () => import('../../components/fieldsets/fieldsets-password/index.vue' /* webpackChunkName: "components/fieldsets-fieldsets-password" */).then(c => wrapFunctional(c.default || c))
export const FieldsetsFieldsetsPersonalDetails = () => import('../../components/fieldsets/fieldsets-personal-details/index.vue' /* webpackChunkName: "components/fieldsets-fieldsets-personal-details" */).then(c => wrapFunctional(c.default || c))
export const InputsDateMonthYearPicker = () => import('../../components/inputs/date-month-year-picker/index.vue' /* webpackChunkName: "components/inputs-date-month-year-picker" */).then(c => wrapFunctional(c.default || c))
export const JobsJobList = () => import('../../components/jobs/job-list/index.vue' /* webpackChunkName: "components/jobs-job-list" */).then(c => wrapFunctional(c.default || c))
export const JobsSingleJob = () => import('../../components/jobs/single-job/index.vue' /* webpackChunkName: "components/jobs-single-job" */).then(c => wrapFunctional(c.default || c))
export const QuestionsHorizontalDragDropYesNoView = () => import('../../components/questions/horizontal-drag-drop-yes-no-view/index.vue' /* webpackChunkName: "components/questions-horizontal-drag-drop-yes-no-view" */).then(c => wrapFunctional(c.default || c))
export const QuestionsHorizontalView = () => import('../../components/questions/horizontal-view/index.vue' /* webpackChunkName: "components/questions-horizontal-view" */).then(c => wrapFunctional(c.default || c))
export const QuestionsQuestionContentView = () => import('../../components/questions/question-content-view/index.vue' /* webpackChunkName: "components/questions-question-content-view" */).then(c => wrapFunctional(c.default || c))
export const QuestionsVerticalDragDropView = () => import('../../components/questions/vertical-drag-drop-view/index.vue' /* webpackChunkName: "components/questions-vertical-drag-drop-view" */).then(c => wrapFunctional(c.default || c))
export const QuestionsVerticalView = () => import('../../components/questions/vertical-view/index.vue' /* webpackChunkName: "components/questions-vertical-view" */).then(c => wrapFunctional(c.default || c))
export const StudentsAddStudent = () => import('../../components/students/add-student/index.vue' /* webpackChunkName: "components/students-add-student" */).then(c => wrapFunctional(c.default || c))
export const StudentsEditStudent = () => import('../../components/students/edit-student/index.vue' /* webpackChunkName: "components/students-edit-student" */).then(c => wrapFunctional(c.default || c))
export const StudentsEditSubscription = () => import('../../components/students/edit-subscription/index.vue' /* webpackChunkName: "components/students-edit-subscription" */).then(c => wrapFunctional(c.default || c))
export const StudentsStudentList = () => import('../../components/students/student-list/index.vue' /* webpackChunkName: "components/students-student-list" */).then(c => wrapFunctional(c.default || c))
export const UsersAddUser = () => import('../../components/users/add-user/index.vue' /* webpackChunkName: "components/users-add-user" */).then(c => wrapFunctional(c.default || c))
export const UsersEditUser = () => import('../../components/users/edit-user/index.vue' /* webpackChunkName: "components/users-edit-user" */).then(c => wrapFunctional(c.default || c))
export const UsersUserList = () => import('../../components/users/user-list/index.vue' /* webpackChunkName: "components/users-user-list" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsDragAndDropGeneralQuestion = () => import('../../components/exam/questions/drag-and-drop-general-question/index.vue' /* webpackChunkName: "components/exam-questions-drag-and-drop-general-question" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsDragAndDropQuestion = () => import('../../components/exam/questions/drag-and-drop-question/index.vue' /* webpackChunkName: "components/exam-questions-drag-and-drop-question" */).then(c => wrapFunctional(c.default || c))
export const ExamQuestionsMultiSelectQuestion = () => import('../../components/exam/questions/multi-select-question/index.vue' /* webpackChunkName: "components/exam-questions-multi-select-question" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
