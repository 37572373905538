
import Vue from 'vue';
import { mapActions } from 'vuex';
import Alerts from '@components/alerts/index.vue';
import layoutHeader from '~/layouts/layout-alpha/layout-alpha-auth/header/index.vue';
// import layoutFooter from '~/layouts/layout-alpha/layout-alpha-auth/footer/index.vue';
import { ApiDictionary, StandardResponse } from '~/@types/meta';
// @ts-ignore
import promotionImage from '~/assets/images/promotion.png';
// @ts-ignore
import promostionSvg from  '~/assets/images/promotion-bubble.svg';
export default Vue.extend({
  components: {
    layoutHeader,
    Alerts,
  },

  data() {
    return {
      isStudent: true,
      collapsedNav: false,
      promotionWindowCode: null,
      promotionWindowSuperHeader: null,
      promotionWindowHeader: null,
      promotionWindowBody: null,
      promotionWindowCtas: [],
      currentPromotionWindowCta: null,
      promotionImage,
      promostionSvg,
    };
  },

  async mounted() {


    
    await this.fetchStudentStatus();

    this.showPromotion();
  },

  created() {
    // if this is the client side, get the user updates
    if (process.client) {
      this.GET_USER_UPDATES();
    }

    // @ts-ignore
    this.$nuxt.$on('isStudent', this.onIsStudentChange);
  },

  methods: {
    ...mapActions('myAccount', ['GET_USER_UPDATES']),
    hidePromotion() {
      this.promotionWindowCode = null;
      window.localStorage.setItem('promotionWindowCodeLastSeen', new Date().toISOString());
    },
    clickedPromotion() {
      this.$api
        .post('/segment/track', {
          event: 'clicked_popup_buy_intro_to_ucat_v1',
          properties: {
            user_id: this.$store.state.myAccount.user?.uuid,
            uid: this.$store.state.myAccount.user?.uuid,
          },
        });
    },
    showPromotion() {

      
      if (this.$store.state.myAccount.user?.is_crimson_student) {
        return;
      }

      if (!this.isStudent) {
        return;
      }

      // IP based country
      if (this.$store.state.myAccount.user?.country_name) {
        this.currentCountry = this.$store.state.myAccount.user?.country_name;
      } else {
        // User's country
        this.currentCountry = this.$store.state.myAccount.user?.country;
      }

      if (this.$config.hela?.promotionWindowCode && this.$config.hela?.promotionWindowCode?.length > 2) {
        const promotionLastSeen = window.localStorage.getItem('promotionWindowCodeLastSeen');
        let promotionLastSeenAsDate = new Date(promotionLastSeen);
        if (isNaN(promotionLastSeenAsDate.getTime())) {
          promotionLastSeenAsDate = new Date('1987-10-03');
        }

        if (
          window.localStorage.getItem('promotionWindowCode') !== this.$config.hela?.promotionWindowCode
          ||
          promotionLastSeenAsDate.getTime() < (new Date().getTime() - 7 * 86400000)
        ) {
          this.promotionWindowSuperHeader = this.$config.hela?.promotionWindowSuperHeader;
          this.promotionWindowCode = this.$config.hela?.promotionWindowCode;
          window.localStorage.setItem('promotionWindowCode', this.promotionWindowCode);

          this.promotionWindowHeader = this.$config.hela?.promotionWindowHeader;
          this.promotionWindowBody = this.$config.hela?.promotionWindowBody;
          // 'au:https://google.au,nz:https://google.co.nz
          this.promotionWindowCtas = this.$config.hela?.promotionWindowCtas.map((cta: string) => {
            const match = cta.match(/(.+?)\((https?:\/\/.+?)\)/);
            if (match) {
              const [, country, url] = match;
              return {
                country,
                url,
              };
            }
            return null;
          }).filter(cta => cta !== null);
          this.currentPromotionWindowCta = this.promotionWindowCtas.find((cta) => cta.country === this.currentCountry);
          if(!this.currentPromotionWindowCta) {
            this.currentPromotionWindowCta = this.promotionWindowCtas[0];
          }
          this.$api
            .post('/segment/track', {
              event: 'shown_popup_buy_intro_to_ucat_v1',
              properties: {
                user_id: this.$store.state.myAccount.user?.uuid,
                uid: this.$store.state.myAccount.user?.uuid,
                country: this.currentCountry,
                cta: this.currentPromotionWindowCta?.url,
              },
            });

        }

      }
    },
    async fetchStudentStatus() {
      const response: StandardResponse<ApiDictionary> = await (this as any).$api.get('dictionaries/student_status');
      if (response?.code === 200) {
        this.$store.commit('user/setStudentStatuses', response.data);
      }
    },

    onIsStudentChange(result: any) {
      this.isStudent = result;
    },
  },

});
