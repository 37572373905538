/**
 * Application form validation rules
 */
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ValidationRules extends Vue {

  // First Name
  public get firstNameRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please enter first name!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  public get examDateRoule() {
    return [
      (v: any) => (v && !!v) || 'Please select the date',
      (v: any) =>
        (v && !!v && parseInt(v) >= new Date().getMonth() + 1) ||
        'Please select an exam date that is in the future!',
    ];
  }

  // Last Name
  public get lastNameRules() {
    return [
      (v: any) => (v && !!v && !!v.trim()) || 'Please enter last name!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Email
  public get emailRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please enter email!',
      (v: any) => v.trim().length >= 5 || 'Please enter minimum 5 characters!',
      (v: any) =>
        // eslint-disable-next-line no-control-regex
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])/.test(
          v,
        ) || 'Please enter a valid email!',
      (v: any) => (v !== null && v.length < 255) || 'Email is too long!',
    ];
  }

  // Bladesence Email
  public get bladesenceEmailRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please enter email!',
      (v: any) => v.trim().length >= 5 || 'Please enter minimum 5 characters!',
      (v: any) => /.+@.+\..+/.test(v) || 'Please enter a valid email!',
      (v: any) => (v !== null && v.length < 255) || 'Email is too long!',
    ];
  }

  // Phone
  public get optionalPhoneRules() {
    return [
      (v: any) =>
        !v || /^[\d\s-]+$/.test(v) || 'Only numbers are allowed here!',
      (v: any) =>
        !v ||
        v.replaceAll('-', '').length >= 6 ||
        'Phone number needs least 6 digits to be valid ',
      (v: any) =>
        !v ||
        v.replaceAll('-', '').length <= 25 ||
        'Phone number is too long. 25 characters or less.',
    ];
  }

  // Phone
  public get phoneRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please enter your phone number!',
      (v: any) => /^[\d\s-]+$/.test(v) || 'Only numbers are allowed here!',
      (v: any) =>
        (v !== null && v !== undefined && v.replaceAll('-', '').length >= 6) ||
        'Phone number needs least 6 digits to be valid ',
      (v: any) =>
        (v !== null && v !== undefined && v.replaceAll('-', '').length <= 25) ||
        'Phone number is too long. 25 characters or less.',
    ];
  }

  // Password
  public get passwordRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please enter password',
      (v: any) =>
        (v !== null && v.length > 7) || 'Please enter at least 8 characters',
      (v: any) =>
        (v !== null &&
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
            v,
          )) ||
        'Minimum eight characters, at least one letter, one number and one special character',
    ];
  }

  // Required field
  public get requiredField() {
    return [
      (v: any) => v !== null || 'Please fill this field',
      (v: any) => (v && v.length !== 0) || 'Please enter this field',
    ];
  }

  // Required field
  public get requiredBoolean() {
    return [(v: any) => v !== null || 'Please fill this field'];
  }

  // Required field allow zeroes
  public get requiredNumberAllowZero() {
    return [
      (v: any) => v !== null || 'Please fill this field',
      (v: any) => !isNaN(v) || 'This field needs to be numeric',
      (v: any) => v.length !== 0 || 'Please enter this field',
    ];
  }

  // Address field
  public get requiredAddressField() {
    return [
      (v: any) =>
        v !== null || 'Please select a search result or enter manually!',
    ];
  }

  // Qubi Casa ID
  public get cubiCasaRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please enter valid CubiCasa email!',
      (v: any) => /.+@.+\..+/.test(v) || 'Please enter valid CubiCasa email!',
      (v: any) =>
        (v !== null && v.length < 255) || 'CubiCasa email is too long!',
    ];
  }

  // User Role
  public get userRoleRules() {
    return [
      (v: any) => (v > 0 && v < 10) || 'Please select a valid user role!',
    ];
  }

  // Service Rules
  public get serviceNameRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter a name!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  public get serviceTypeRules() {
    return [
      (v: any) => !!v || 'Please select a type!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  public get serviceGroupRules() {
    return [
      (v: any) => (!!v && !!v.trim()) || 'Please select a group!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  public get serviceDescriptionRules() {
    return [
      (v: any) => !!v || 'Please enter description!',
      (v: any) => (v && v.length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  public get serviceCostRules() {
    return [
      (v: any) => (!!v && !!String(v).trim()) || 'Please enter a valid cost!',
    ];
  }

  public get serviceUploadLimitRules() {
    return [
      (v: any) =>
        (!!v && !!String(v).trim()) || 'Please enter a valid upload limit!',
    ];
  }

  public get serviceNumberOfFilesRules() {
    return [
      (v: any) =>
        (!!v && !!String(v).trim()) ||
        'Please enter a valid number of file limit!',
    ];
  }

  public get serviceShootLengthRules() {
    return [
      (v: any) =>
        (!!v && !!String(v).trim()) || 'Please enter a valid shoot length!',
    ];
  }

  /**
   * Booking package select rules
   * @private
   */
  public get packageServiceSelectRules() {
    return [(v: any) => !!v || 'Please select a service!'];
  }

  /**
   * Booking package select rules
   *
   * @private
   */
  public get bookingPackageSelectRules() {
    return [(v: any) => !!v || 'Please select a package!'];
  }

  /**
   * Booking cost rules
   *
   * @private
   */
  public get bookingCostRules() {
    return [
      (v: any) => !!v || 'Please enter cost!',
      (v: any) => (v && v >= 1) || 'Please enter valid cost!',
    ];
  }

  // Job Location Rules
  public get bookingJobLocationRules() {
    return [(v: any) => (v != null && !!v) || 'Please enter job location!'];
  }

  // Booking Street Rules
  public get bookingStreetRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter street!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Booking Address Line One Rules
  public get bookingAddressLineOneRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter address line 1!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Booking Address Line Two Rules
  public get bookingAddressLineTwoRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter address line 2!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Booking Address Subyrb Rules
  public get bookingSuburbRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter suburb!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Booking City Rules
  public get bookingCityRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter city!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Booking postcode Rules
  public get bookingPostcodeRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter postcode!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  // Booking Job Requirements Rules
  public get bookingJobRequirementsRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter job Requirements!',
      (v: any) =>
        (v && v.trim().length >= 2) || 'Please enter minimum 2 characters!',
    ];
  }

  /**
   * Booking package select rules
   *
   * @private
   */
  public get bookingSelectMapRules() {
    return [(v: any) => !!v || 'Please select a location!'];
  }

  /**
   * Booking schedule date rules
   *
   * @private
   */
  public get bookingScheduleDateRules() {
    return [
      (v: any) => (v != null && !!v) || 'Please enter date!',
      (v: any) => (v && v.trim().length >= 2) || 'Please enter valid date!',
    ];
  }

  /**
   * Booking schedule time rules
   *
   * @private
   */
  public get bookingScheduleTimeRules() {
    return [(v: any) => (v != null && !!v) || 'Please enter time!'];
  }

  public get maxLength255() {
    return [(v: any) => (v && v.length < 250) || 'Too long!'];
  }
}
