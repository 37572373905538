import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';
import { Answer, Module, Question } from '~/@types/exam';
import ROLES from '~/constants/roles';

export const questionFactory = (context: Context) => ({
  async save (module: Module): Promise<ApiReturn> {
    return await context.$api.post('/questions', module);
  },

  async update (module: Module) {
    return await context.$api.put(`/questions/${module.id}`, module);
  },

  async fetch (module_id: number) {
    return await context.$api.get(`/questions/${module_id}`);
  },

  async upload (file: any, question_id: number) {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);

    return await context.$api.post(
      `questions/${question_id}/upload`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },

  async newQuestion (module_id: number) {
    return await context.$api.post(`/questions/${module_id}/question`);
  },

  async uploadQuestionImage (file: File, question: Question) {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);

    return await context.$api.post(
      `/questions/question/${question.id}/image`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },

  async uploadAnswerJustificationImage (file: File, question: Question) {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);

    return await context.$api.post(
      `/questions/question/${question.id}/answer-image`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },

  async addAnswer (question_id: number) {
    return await context.$api.post(
      `/questions/questions/${question_id}/answer`,
    );
  },

  async uploadAnswerImage (file: any, answer: Answer) {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);

    return await context.$api.post(
      `/questions/answer/${answer.id}/image`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },

  async markModuleAsTested (module: Module) {
    return await context.$api.put(`/questions/${module.id}/tested`, {
      tested: true,
    });
  },

  async submitForApproval (module: Module) {
    if (
      !module.last_tested_at ||
      context.$moment(module.last_tested_at).unix() <
        context.$moment(module.updated_at).unix()
    ) {
      return context.$alert.show('Please Test at least one more time', 'info');
    }
    return await context.$api.put(`/questions/${module.id}/approval`, {
      tested: true,
    });
  },

  async publishModule (module: Module) {
    // @ts-ignore
    if (context.$auth.user.role_id !== ROLES.ROLE_SUPER_ADMIN) {
      return context.$alert.show(
        'You do not have permission to publish a question',
        'warning',
      );
    }
    return await context.$api.put(`/questions/${module.id}/publish`, {
      published: true,
    });
  },

  async archiveModule (module: Module) {
    if (
      !((context as any).$auth.user.role_id === ROLES.ROLE_SUPER_ADMIN) &&
      !(
        (context as any).$auth.user.role_id === ROLES.ROLE_CONTENT_WRITER &&
        (context as any).$auth.user.id === module.created_by
      )
    ) {
      return context.$alert.show(
        'You do not have permission to archive this Question',
        'info',
      );
    }
    return await context.$api.put(`/questions/${module.id}/archive`, {
      archive: true,
    });
  },

  async flag (module_id: number, question_id: number) {
    return await context.$api.post(
      `/questions/${module_id}/${question_id}/flag`,
    );
  },

  async cloneModule (module_id: number) {
    return await context.$api.post(`/questions/${module_id}/clone`);
  },
});
