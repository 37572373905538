// plugins/ybug.js

export default ({ store }) => {
  window.ybug_settings = {
    "id": "ba2d3aav51jqpbc0g0q3",
    console_log: true, 
        
    onload: () => { 
      window.Ybug.on('beforesend', (feedback) => {

        console.debug('user', {
          name: store.$auth?.$state.user.first_name + ' ' + store.$auth?.$state.user.last_name,
          exam_date: store.$auth?.$state.user.formatted_exam_date,
          email: store.$auth?.$state.user.email,
          user_id: store.$auth?.$state.user.id,
        });
      });
    },
  };
  const ybug = document.createElement('script');
  ybug.type = 'text/javascript';
  ybug.async = true;
  ybug.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(ybug, s);
};
